import React from 'react';

import * as S from './Card.css';

const Card = ({ children, noHover, ...rest }) => {
  return (
    <S.Card {...rest} noHover={noHover}>
      {children}
    </S.Card>
  );
};

export default Card;
