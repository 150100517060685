import React, { Component } from 'react';
import Slider from 'react-slick';

class Custom extends Component {
  componentDidMount() {
    window && window.addEventListener('touchstart', this.touchStart);
    window && window.addEventListener('touchmove', this.preventTouch, { passive: false });
  }

  componentWillUnmount() {
    window && window.removeEventListener('touchstart', this.touchStart);
    window && window.removeEventListener('touchmove', this.preventTouch, { passive: false });
  }

  touchStart(e) {
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  }

  preventTouch(e) {
    const minValue = 5; // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(this.clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  }

  render() {
    const { children, ...rest } = this.props;

    return <Slider {...rest}>{children}</Slider>;
  }
}

export default Custom;
