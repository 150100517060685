import { transition, Box, styled } from '@smooth-ui/core-sc';

export const Card = styled(Box)`
  position: relative;
  ${transition('transform 0.3s')};
  pointer-events: ${props => props.isSold && 'none'};
  will-change: transform;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    will-change: opacity;

    @media (max-width: 1139px) and (min-width: 768px) {
      box-shadow: ${props =>
        !props.isSold &&
        `0 16px 16px 0 rgba(0, 8, 8, 0.2), 0 8px 8px 0 rgba(0, 113, 107, 0.2),
      0 4px 4px 0 rgba(0, 113, 107, 0.2), 0 2px 2px 0 rgba(0, 113, 107, 0.2),
      0 1px 1px 0 rgba(0, 113, 107, 0.2)`};
    }

    box-shadow: ${props =>
      !props.isSold &&
      `0 32px 32px 0 rgba(0, 8, 8, 0.2), 0 16px 16px 0 rgba(0, 113, 107, 0.2),
      0 8px 8px 0 rgba(0, 113, 107, 0.2), 0 4px 4px 0 rgba(0, 113, 107, 0.2),
      0 2px 2px 0 rgba(0, 113, 107, 0.2)`};

    opacity: 1;
    ${transition('opacity 0.3s')};
  }

  @media (min-width: 768px) {
    ${props =>
      !props.noHover &&
      `
    &:hover {
      transform: translateY(-4px);
      &::after {
        opacity: 0.6;
      }
    }`}
  }
`;
