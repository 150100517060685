import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Grid, Row, Col, Box, Typography } from '@smooth-ui/core-sc';
import BlockContent from 'components/block-content';
import Button from 'components/Button';
import Link from 'components/Link';
import Card from 'components/Card';
import Mask from 'components/Mask';
import SEO from 'components/seo';
import Layout from 'components/Layout';
import Contact from 'components/Forms/Contact';
import Slider from 'components/Slider';

export const query = graphql`
  query AboutPageQuery {
    sanityAbout {
      intro_title
      _rawIntroCopy
      intro_image {
        asset {
          small: fixed(toFormat: JPG, width: 800, height: 960) {
            src
          }
          large: fixed(toFormat: JPG, width: 553, height: 886) {
            src
          }
        }
      }
      credit_title
      _rawCreditCopy
      credit_button
      credit_image {
        asset {
          fluid(toFormat: JPG, maxWidth: 390, maxHeight: 580) {
            ...GatsbySanityImageFluid
          }
        }
      }
      insurance_title
      _rawInsuranceCopy
      insurance_button
      insurance_image {
        asset {
          fluid(toFormat: JPG, maxWidth: 580, maxHeight: 580) {
            ...GatsbySanityImageFluid
          }
        }
      }
      team_title
      team {
        name
        email
        description
        photo {
          asset {
            fluid(toFormat: JPG, maxWidth: 360, maxHeight: 310) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      contact {
        title
        image {
          asset {
            fixed(toFormat: JPG, width: 565, height: 750) {
              src
            }
          }
        }
      }
    }
  }
`;

const AboutPage = ({ data: { sanityAbout } }) => {
  const about = sanityAbout;

  return (
    <Layout>
      <SEO title="Over ons" />
      <Grid mb={{ xs: 6, md: 18 }}>
        <Row mb={{ xs: 4, md: 0 }}>
          <Col xs={12} md={6}>
            <Box mt={'-40%'} mb={{ xs: 50, md: 0 }} ml={{ xs: '-20px', sm: 0 }}>
              <Mask
                large={about.intro_image.asset.large.src}
                small={about.intro_image.asset.small.src}
              />
            </Box>
          </Col>
          <Col mt={{ md: 120 }} xs={12} md={6}>
            <Box>
              <Typography variant="h1" mb={4}>
                {about.intro_title}
              </Typography>
              <BlockContent blocks={about._rawIntroCopy || []} />
            </Box>
          </Col>
        </Row>
        <Row alignItems="center" mb={{ xs: 4, md: 0 }}>
          <Col xs={12} md={6} my={{ xs: 4 }}>
            <Typography variant="h2" mb={4}>
              {about.credit_title}
            </Typography>
            <BlockContent blocks={about._rawCreditCopy || []} />
            <Button as={Link} to="/kredieten">
              {about.credit_button}
            </Button>
          </Col>
          <Col md={4.5} omd={1.5}>
            <Card noHover>
              <Img fluid={about.credit_image.asset.fluid} />
            </Card>
          </Col>
        </Row>
        <Row alignItems="center">
          <Col order={{ xs: 2, md: 1 }} md={6}>
            <Card noHover>
              <Img fluid={about.insurance_image.asset.fluid} />
            </Card>
          </Col>
          <Col order={{ xs: 1, md: 2 }} xs={12} omd={1} md={5} my={{ xs: 4 }}>
            <Typography variant="h2" mb={4}>
              {about.insurance_title}
            </Typography>
            <BlockContent blocks={about._rawInsuranceCopy || []} />
            <Button as={Link} to="/verzekeringen">
              {about.insurance_button}
            </Button>
          </Col>
        </Row>
      </Grid>
      <Grid>
        <Typography mt={{ xs: 8, md: 0 }} mb={{ xs: 4, md: 6 }} textAlign="center" variant="h1">
          {about.team_title}
        </Typography>
        <Box mx={-3} pb={{ xs: 6, md: 18 }} overflow="hidden">
          <Slider
            slidesToShow={3}
            className="carousel__slider--team"
            arrows={false}
            infinite={false}
            swipeToSlide={true}
            draggable={true}
            responsive={[
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]}
          >
            {about.team.map((member, i) => (
              <Box key={i} px={{ xs: '5px', md: 2 }} height={1}>
                <Card noHover display="flex" height={1} flexDirection="column">
                  <Img fluid={member.photo.asset.fluid} />
                  <Box
                    backgroundColor="white"
                    p={'20px'}
                    style={{ flexGrow: 1, userSelect: 'text' }}
                  >
                    <Typography variant="h3" fontSize={3} mb={0}>
                      {member.name}
                    </Typography>
                    <Typography
                      as="a"
                      href={`mailto:${member.email}`}
                      opacity={0.5}
                      style={{ wordBreak: 'break-all' }}
                    >
                      {member.email}
                    </Typography>
                    <Typography mt={3} as="p">
                      {member.description}
                    </Typography>
                  </Box>
                </Card>
              </Box>
            ))}
          </Slider>
        </Box>
      </Grid>
      <Contact
        formName="Over Ons"
        title={about.contact.title}
        image={about.contact.image.asset.fixed.src}
      />
    </Layout>
  );
};

export default AboutPage;
